import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PsychologyIcon from '@mui/icons-material/Psychology';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import MainNavbar from "./MainNavbar";
import SubSidebar from "./SubSidebar";
import MainSidebar from "./MainSidebar";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "../../helpers/Functions";
import { connect } from "react-redux";
import { isNotStudent } from "../../helpers/Utils";
import { Drawer } from "@mui/material";

import "../../assets/content.css";
import 'react-toastify/dist/ReactToastify.css';
import { getUserByToken } from "../../app/crud/person.crud";
import { setUserDetails } from "../../app/redux/slices/authSlice";
import displayToast from "../partials/DisplayToast";

const layoutContext = createContext();
const MAIN_SIDEBAR_WIDTH_MINIMIZE = 60;
const MAIN_SIDEBAR_WIDTH_OPEN = 200;
const SUB_SIDEBAR_WIDTH_OPEN = 278;

export function useLayoutContext() {
	return useContext(layoutContext);
}

const Layout = ({ 
	currentCapabilities, 
	setUserDetails,
}) => {
	const [primaryOptions, setPrimaryOptions] = useState([]);
	const [navigationOptions, setNavigationOptions] = useState({});
	const [selectedPrimaryValue, setSelectedPrimaryValue] = useState(primaryOptions?.[0]?.value);
	const [uniqSelectedNavValue, setUniqSelectedNavValue] = useState(); // for catching title events
	const [selectedNavValue, setSelectedNavValue] = useState();
	const [selectedNavItemValue, setSelectedNavItemValue] = useState();

	const [isMainSidebarOpen, setIsMainSidebarOpen] = useState(true);
	const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);

	const [layoutClassName, setLayoutClassName] = useState('');
	const [update, setUpdate] = useState(true);

	const location = useLocation();
	const isInLibraryPage = useMatch('/dashboard/:type/:library_code');
	const isInAdminPanel = location.pathname.startsWith('/admin-panel');


	useEffect(() => {
		setIsMainSidebarOpen(!isInLibraryPage);
	}, [location]);

	useEffect(() => {
		if (update) {
			getUserByToken().then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const parsedUserDetail = {
						...response.data.data[0],
						user_img: response.data.data[0].image_file,
						additional_info: JSON.parse(response.data.data[0].additional_info)
					};
					
					setUserDetails(parsedUserDetail)
				}

				setUpdate(false);
			})
		}
	}, [update]);

	const addPrimaryOption = (option) => {
		setPrimaryOptions((prevPrimaryOptions) => {
			let existingOption = prevPrimaryOptions.find((opt) => opt?.value === option?.value);

			if (isEmpty(existingOption)) {
				let newIconOptions = [...prevPrimaryOptions, option];
				newIconOptions.sort((a, b) => a?.position - b?.position);

				return newIconOptions;
			}

			return prevPrimaryOptions;
		});
	};

	const setNavigationOptionsFor = (value, navOption) => {
		let newNavOptions = { ...navigationOptions };
		newNavOptions[value] = navOption;

		setNavigationOptions(newNavOptions);
	}

	const getNavigationOptionsFor = (value) => {
		return [...navigationOptions?.[value] ?? []];
	}

	const dashboardUrlByRole = () => {
		if (isNotStudent(currentCapabilities.role)) {
			if (currentCapabilities?.is_limited) {
				return "/dashboard/course-sales";
			}

			return "/dashboard/admin-dashboard";
		}
		else {
			return "/dashboard/student-dashboard";
		}
	}

	useEffect(() => {
		setNavigationOptions({});
		setIsSubSidebarOpen(false);

		const DEFAULT_DASHBOARD_PRIMARY_OPTIONS = [
			{
				value: 0,
				title: 'Dashboard',
				icon: (<BusinessIcon className="theme-sub-nav-icon" />),
				url: dashboardUrlByRole(),
				hidden: isInAdminPanel,
				line_break: true,
			},
			{
				value: 1,
				title: 'Online Courses',
				icon: (<LocalLibraryOutlinedIcon className="theme-sub-nav-icon" />),
				url: ['/dashboard/trainings', '/dashboard'],
				hidden: isInAdminPanel,
			},
			{
				value: 2,
				title: 'In-Person Trainings',
				icon: (<PersonPinOutlinedIcon className="theme-sub-nav-icon" />),
				url: '/dashboard/in-person-trainings',
				hidden: isInAdminPanel,
			},
			{
				value: 3,
				title: 'Webinars',
				icon: (<OndemandVideoIcon className="theme-sub-nav-icon" />),
				url: '/dashboard/webinars',
				hidden: isInAdminPanel,
			},
			{
				value: 4,
				title: 'Intelliguide',
				icon: (<PsychologyIcon className="theme-sub-nav-icon" />),
				url: '/dashboard/intelliguide',
				hidden: isInAdminPanel,
				line_break: isNotStudent(currentCapabilities.role),
			},
			{
				value: 5,
				title: 'Companies',
				icon: (<BusinessIcon className="theme-sub-nav-icon" />),
				url: '/admin-panel/companies',
				hidden: !isInAdminPanel || !isNotStudent(currentCapabilities.role),
			},
			{
				value: 6,
				title: 'Settings',
				icon: (<SettingsIcon className="theme-sub-nav-icon" />),
				url: isInAdminPanel ? '/admin-panel/settings' : '/dashboard/settings',
				hidden: !isNotStudent(currentCapabilities.role),
			},
		];

		if (!isInLibraryPage) {
			setPrimaryOptions(DEFAULT_DASHBOARD_PRIMARY_OPTIONS);
		}
	}, [currentCapabilities]);

	const drawerWidth = MAIN_SIDEBAR_WIDTH_MINIMIZE + (isSubSidebarOpen ? SUB_SIDEBAR_WIDTH_OPEN : 0) + (isMainSidebarOpen ? MAIN_SIDEBAR_WIDTH_OPEN : 0);

	return (
		<layoutContext.Provider
			value={{
				layoutClassName,
				primaryOptions,
				navigationOptions,
				isMainSidebarOpen,
				isSubSidebarOpen,
				selectedPrimaryValue,
				uniqSelectedNavValue,
				selectedNavValue,
				selectedNavItemValue,
				setLayoutClassName,
				setPrimaryOptions,
				setIsMainSidebarOpen,
				setIsSubSidebarOpen,
				setSelectedPrimaryValue,
				setUniqSelectedNavValue,
				setSelectedNavValue,
				setSelectedNavItemValue,
				setNavigationOptionsFor,
				addPrimaryOption,
				getNavigationOptionsFor,
			}}
		>
			<ToastContainer />
			<div className="theme-left-container">
				<Drawer
					variant="permanent"
					open={isSubSidebarOpen}
					sx={{
						width: `${drawerWidth}px`,
						position: 'relative',
						transition: 'width 0.5s ease',
						'& .MuiDrawer-paper': {
							width: `${drawerWidth}px`,
							overflowX: 'hidden',
							transition: 'width 0.5s ease',
							flexDirection: 'row',
						},
						zIndex: 0,
						display: 'flex',
					}}
				>
					<MainSidebar 
						onValueSelect={(primval) => {
							if (primval === selectedPrimaryValue && navigationOptions?.[primval]?.length > 0) {
								setIsSubSidebarOpen(!isSubSidebarOpen);
							}
							else {
								setSelectedPrimaryValue(primval);
							}
						}}
					/>
					<SubSidebar
						onNavigationClick={(navval) => {
							setUniqSelectedNavValue(navval);
							setSelectedNavValue(null);
							setSelectedNavItemValue(null);
						}}

						onNavigationItemClick={(navval, navitemval) => {
							setSelectedNavValue(navval);
							setSelectedNavItemValue(navitemval);
							setUniqSelectedNavValue(null);
						}}
					/>
				</Drawer>
			</div>
			<div id="main-panel-container" className="theme-right-container d-none d-md-flex">
				<div className="w-100">
					<MainNavbar 
						update={update}
						setUpdate={setUpdate}
					/>
					<div className={`theme-content ${layoutClassName}`}>
						<Outlet />
					</div>
				</div>
			</div>
		</layoutContext.Provider>
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities
	}

	return data;
}

const mapDispatchToProps = {
	setUserDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);